import React from "react"
import styled from "styled-components"

import { VSection, ValueProp, Grid, BasicCard } from "components/common"

import { device } from "utils"

const PartnersExisting = ({ valueProps }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => (
        <BasicCard>
          <ValueProp
            {...vp}
            subtitleAs="pSmall"
            disableLink={false}
            ctasTheme={[{ target: "_blank" }, { target: "_blank" }]}
          />
        </BasicCard>
      )}
    />
  )

  return (
    <StyledPartnersExisting>
      <VSection grid={renderGrid} />
    </StyledPartnersExisting>
  )
}

const StyledPartnersExisting = styled.div`
  --button-mobile-font-size: 0.875rem;
  --button-font-size: 0.875rem;
  --button-padding: 16px 20px;
  --cta-mobile-grid-gap: var(--sp-8);
  --cta-grid-gap: var(--sp-8);
  --grid-gap: var(--sp-24);
  --grid-col: auto-fit;
  --grid-col-min: 320px;

  .vp_mediaContainer {
    .gatsbyImageWrap {
      img {
        max-height: 60px;
        width: auto;
        height: auto;
      }
    }
  }
  .vp_ctaContainer {
    .cta-wrap {
      grid-template-columns: 1fr;
      width: 100%;

      @media ${device.laptop} {
        grid-template-columns: auto auto;
      }

      button {
        display: block;
        width: 100%;
      }
    }
  }
`

export default PartnersExisting
