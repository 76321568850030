import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { useContentfulSection, useMeta } from "utils"

import { Layout, Seo } from "components/common"

import { PrimaryNav, MobileNav } from "src/components/navigation"

import { PartnersHero, PartnersExisting } from "src/sections/partners"

const PartnersPage = ({ data: { partners } }) => {
  const meta = useMeta(partners)
  const { partners_hero, partners_existing } = useContentfulSection(
    partners.sections
  )

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />
      <PartnersFormWrap>
        <PartnersHero {...partners_hero} />
        <PartnersExisting {...partners_existing} />
      </PartnersFormWrap>
    </Layout>
  )
}

const PartnersFormWrap = styled.div``

export const query = graphql`
  {
    partners: contentfulPage(pageId: { eq: "partners" }) {
      ...Page
    }
  }
`

export default PartnersPage
