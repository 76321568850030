import React from "react"
import styled from "styled-components"

import { Cta, VSection } from "components/common"

import { matrixBackgroundStyle } from "utils"

const BecomePartnersHero = ({ title, subtitle, ctas, backgroundImage }) => {
  return (
    <StyledBecomePartnersHero>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="pSmall"
        backgroundImage={backgroundImage}
        cta={() => <Cta ctas={ctas} />}
        critical
      />
    </StyledBecomePartnersHero>
  )
}

const StyledBecomePartnersHero = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-bottom: 0;
  --section-padding-bottom: 0;
  --section-align: center;
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
    --tg-max-width: 460px;
  }

  .v_ctaContainer {
    margin-top: var(--sp-32);
    --button-gradient-border-color: linear-gradient(
      90deg,
      #a6d683 0%,
      #cfd175 50.15%,
      #e0aa7d 100.11%
    );
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }
`

export default BecomePartnersHero
